import React, { useState } from "react";
import CategoryFilter from "./CategoryFilter";
import { QnaCard } from ".";
import CsBox from "./CsBox";
import "./FAQ.css";
import HorizonLine from "./HorizontalLine";
import Header from "../component/header/Header";

const categories = [
  {
    name: "전체",
    value: "all",
  },
  {
    name: "주문/결제",
    value: "category1",
  },
  {
    name: "배송관련",
    value: "category2",
  },
  {
    name: "취소/환불",
    value: "category3",
  },
  {
    name: "반품/교환",
    value: "category4",
  },
  {
    name: "증빙서류발급",
    value: "category5",
  },
  {
    name: "로그인/회원정보",
    value: "category6",
  },
  {
    name: "서비스/기타",
    value: "category7",
  },
];

const qnaList = [
  {
    category: "category1",
    question: "주문 내역은 어떻게 확인할 수 있나요?",
    answer:
      "우측 상단 프로필 사진을 클릭 후 [나의 쇼핑]을 통해 확인 가능합니다",
  },
  {
    category: "category1",
    question: "결제 방법은 어떤것이 있나요?",
    answer:
      "신용카드 및 체크카드, 무통장입금, 휴대폰 소액결제, 네이버페이를 이용해 결제 가능합니다",
  },
  {
    category: "category1",
    question: "비회원주문 및 전화주문이 가능한가요?",
    answer:
      "전화주문은 준비중에 있습니다.<br>비회원 주문은 가능하지만 일부 상품에 한해 제한되어있습니다.",
  },
  {
    category: "category1",
    question: "신용카드 무이자 할부가 되나요?",
    answer: "각 카드사 별로 상이하며, 카드사를 통해 확인 가능합니다.",
  },
  {
    category: "category1",
    question: "신용카드 결제 후 할부 개월 수를 변경 가능한가요?",
    answer:
      "결제 후 결제 정보 변경은 불가능 합니다.\n단, 결제 완료 단계에서는 취소 후 재주문을 통해 변경 가능합니다.",
  },
  {
    category: "category1",
    question: "가상계좌 은행을 변경 할 수 있나요?",
    answer:
      "한번 발급 받은 계좌번호는 변경이 불가능 합니다.\n재주문을 통해 새로운 계좌를 발급 받으신 후 입금 부탁드립니다.",
  },
  {
    category: "category1",
    question: "주문 후 결제 방법을 변경하고 싶은데 어떻게 해야 하나요?",
    answer:
      "결제 후 결제 정보 변경은 불가능 합니다.\n단, 입금 대기 및 결제 완료 단계에서는 취소 후 재주문을 통해 변경 가능합니다.",
  },
  {
    category: "category1",
    question: "결제 시 에러 메시지가 나오는 경우 어떻게 해야하나요?",
    answer:
      "우측 상단 프로필 사진을 클릭 후 [나의 쇼핑 > 고객센터 > 1 : 1 카톡 상담하기]를 통해 문의 부탁드립니다.",
  },
  {
    category: "category1",
    question: "신용카드 안전결제(ISP)는 무엇인가요?",
    answer:
      "국민카드 BC카드는 인터넷 안전결제(ISP)로 결제가 진행됩니다.\n결제 진행시 나타나는 안내에 따라 결제하실 카드번호와 사용하시는 이메일 및 비밀번호를 입력하여 인터넷 안전결제(ISP)등록 후 결제를 진행해 주시면 됩니다.\n단, 30만원 이상 결제 시에는 공인인증서가 필요합니다.",
  },
  {
    category: "category1",
    question: "가상계좌 입금은 언제 확인 되나요?",
    answer:
      "입금 후 24시간 이내에 확인됩니다.\n24시간 이후에도 입금확인이 되지 않는 경우 결제 금액과 입금한 금액이 같은지, 올바른 계좌로 입금하였는지 확인 후 [나의 쇼핑 > 고객센터 > 1 : 1 카톡 상담하기]를 통해 문의 부탁드립니다.",
  },

  {
    category: "category1",
    question: "가상계좌 입금인을 다르게 적은 경우 어떻게 해야 하나요?",
    answer: "입금인이 달라도 가상계좌번호만 같으면 문제 없습니다.",
  },
  {
    id: "category2__deliveryFee",
    category: "category2",
    question: "배송비는 얼마인가요?",
    answer:
      "우리의 집은 상품정보 중계 및 판매 매체이며, 판매 업체 별로 배송비 정책이 상이합니다.\n각 상품상세페이지에서 배송비를 확인하실 수 있습니다.",
  },
  {
    category: "category2",
    question: "배송 확인은 어떻게 하나요?",
    answer:
      "우측 상단 프로필의 [나의 쇼핑]을 통해 배송단계를 한눈에 보실 수 있습니다.\n또한 배송이 시작되면 카카오톡 알림톡 또는 SMS로 안내메시지가 발송됩니다.",
  },
  {
    category: "category2",
    question: "배송은 얼마나 걸리나요?",
    answer:
      "상품 배송 기간은 배송 유형에 따라 출고 일자 차이가 있습니다. 자세한 사항은 구매하신 상품의 상세페이지에서 확인 가능하며, 배송 유형 별 기본 출고 기간은 아래와 같습니다.\n·일반 택배 / 화물택배 : 결제 후 1 ~ 3 영업일 이내 출고됩니다.\n·업체 직접 배송 : 배송 지역에 따라 배송 일자가 상이할 수 있으므로 상품 상세 페이지에서 확인해 주세요.\n＊ 영업일은 주말, 공휴일을 제외한 기간입니다.\n＊ 제조사의 사정에 따라 출고일은 지연될 수 있는 점 양해 부탁드립니다.",
  },
  {
    category: "category2",
    question: "여러 상품을 묶음 배송 받으려면 어떻게 해야하나요?",
    answer:
      "각 상품별로 배송처가 상이할 수 있기 때문에 묶음 배송은 어렵습니다.\n단, 배송처가 같은 경우 배송처의 정책에 따라 가능할 수 있습니다.",
  },
  {
    category: "category2",
    question: "원하는 날짜로 맞춰서 배송을 받을 수 있나요?",
    answer: "각 배송처 정책에 따라 상이할 수 있습니다.",
  },
  {
    category: "category2",
    question:
      "배송 시작 알림 메시지를 받았는데, 배송추적이 되지 않습니다. 어떻게 해야하나요?",
    answer:
      "송장번호 등록 후 1영업일 이내 또는 실제 상품배송이 진행됨과 동시에 배송추적이 가능합니다.\n* 배송처에서 배송이 시작되기 전, 송장을 먼저 출력 후 송장번호를 입력하는 경우가 있습니다.",
  },
  {
    category: "category2",
    question:
      "배송조회를 해보면 배송완료로 확인되는데 택배를 받지 못했습니다. 어떻게 해야하나요?",
    answer:
      "경비실 또는 무인택배함을 먼저 확인 부탁드립니다.\n별도의 위탁 장소가 없는 경우 배송기사님께서 임의의 장소에 보관하셨을 수 있으니, 기사님께 문의 부탁드립니다.",
  },
  {
    category: "category2",
    question: "해외배송이 가능한가요?",
    answer: "현재는 국내배송만 진행하고 있습니다.",
  },
  {
    id: "category3__orderCancel",
    category: "category3",
    question: "주문 취소는 어떻게 하나요?",
    answer:
      "판매처에서 주문을 확인하기 전이라면 언제든 취소하실 수 있으며, [나의 쇼핑 > 주문배송내역 조회 > 주문상세보기]에서 직접 주문취소하실 수 있습니다.\n* 이미 판매처에서 상품을 확인해 준비 중이라면, 직접 취소가 어렵습니다. 상세페이지에서 확인되는 판매자 연락처 또는 우리의집 고객센터로 문의 부탁드립니다.",
  },
  {
    category: "category3",
    question: "취소 후 환불은 언제되나요?",
    answer:
      "신용카드 및 체크카드의 경우 카드사에서 확인 절차를 거치는 관계로 평균 3 ~ 7일 영업일 이내 환불처리가 완료됩니다.\n 무통장 입금의 경우 평균 3영업일 이내 요청하신 계좌로 환불됩니다.\n 휴대폰 소액결제의 경우 평균 3영업일 이내 환불 또는 취소 처리가 완료됩니다.",
  },
  {
    category: "category3",
    question: "여러 개의 상품을 주문했는데, 일부만 취소할 수도 있나요?",
    answer:
      "신용카드 및 체크카드 또는 무통장 입금의 경우 배송 상태에 따라 결제 후 부분 취소가 가능하며, 배송상태에 따른 취소 가능 여부는 아래와 같습니다.\n - 입금대기 및 결제 완료 단계: 우측 상단 프로필 사진을 클릭 후 [나의 쇼핑 > 주문배송내역 조회]에서 즉시취소가능합니다\n - 배송준비중: 우측상단 프로필 사진을 클릭후 [나의 쇼핑 > 고객센터 > 1 : 1 카톡 상담하기]로 취소 가능 여부 문의드립니다.\n - 배송중: 주문 취소가 불가능합니다. 수령후 반품 처리 부탁드립니다. \n * 단, 가상계좌로 입금 전이라면 부분 취소처리가 불가능하므로 재주문 부탁드립니다.",
  },
  {
    id: "category4__defectiveProduct",
    category: "category4",
    question: " 제품이 불량입니다. 반품 혹은 교환은 어떻게 하나요?",
    answer:
      "업체(브랜드)마다 발송처 및 반품절차가 다릅니다\n- 우측 상단 프로필 사진을 클릭 후 [나의 쇼핑] 페이지에서 원하는 주문의 상세보기 번튼을 클릭 후 교환/접수 후 진행 할 수 있습니다. 교환/반품 접수 없이 임의로 반송한 경우에는 처리가 늦어질 수 있습니다.\n - 교환/반품 접수 시 원활한 처리를 위해 불량 사진이 필요하오니, 가급적 사진을 첨부하여 주시기 바랍니다.\n 단, 구매확정 이후 교환/반품을 원하시는 경우 판매 업체에 교환/반품 가능 여부를 먼저 문의 부탁드립니다.",
  },
  {
    category: "category4",
    question: "제품의 교환 또는 반품을 할 수 있나요?",
    answer:
      "상품을 수령하신 후 7일 이내에 교환, 반품이 가능하며, 고객님의 변심에 의한 교환/반품의 경우 배송비용이 부과될 수 있습니다.\n* 단, 아래의 경우 교환/반품이 불가능합니다.\n- 고객님의 책임 사유로 인해 상품 등이 멸실 또는 훼손된 경우\n- 개봉 및 포장의 훼손으로 상품가치가 현저히 상실된 경우\n- 시간 경과에 의해 재판매가 어려울 정도로 상품 가치가 현저히 저하된 경우 \n- 고객 주문 확인 후 상품 제작에 들어가는 주문 제작 상품\n- 직접 조립하는(DIY) 상품을 조립 한 경우",
  },
  {
    category: "category4",
    question: "제품의 교환 또는 반품은 어떻게 신청 할 수 있나요?",
    answer:
      "업체(브랜드)마다 발송처 및 반품절차가 다릅니다.\n- 우측 상단 프로필 사진을 클릭 후 [나의 쇼핑 > 주문 상세보기]페이지에서 원하는 주문의 상세보기 버튼을 클릭 후 교환/반품 접수 후 진행 할 수 있습니다.\n 교환/반품 접수 없이 임의로 반송한 경우에는 처리가 늦어질 수 있습니다.\n- 반품을 보내실 떄는 제품이 훼손되지 않도록 안전하게 충분히 포장 부탁드립니다.\n 단. 구매확정 이후 교환/반품을 원하시는 경우 판매 업체에 교환/반품 가능 여부를 먼저 문의 부탁드립니다.",
  },
  {
    category: "category4",
    question: "반품 시, 사은품도 같이 반품해야 하나요?",
    answer: "사은품도 같이 동봉하여 반품해 주셔야 반품처리가 완료됩니다.",
  },
  {
    category: "category4",
    question: "주문한 것과 다른 상품이 왔습니다. 어떻게 해야하나요?",
    answer:
      "우측 상단 프로필 사진을 클릭 후 [나의 쇼핑 > 고객센터 > 1 : 1 카톡 상담하기]를 통해 문의 주시면 확인 도움드리겠습니다.",
  },
  {
    category: "category5",
    question: "세금계산서를 받고싶은데 어떻게 해야하나요?",
    answer:
      "세금계산서 발급은 어려우며, 결제 시 지출 증빙 또는 현금영수증 발행은 가능합니다.",
  },
  {
    category: "category5",
    question: "주문 후 지출증빙/현금영수증 발행이 가능한가요?",
    answer:
      "주문 완료 후 지출증빙/현금영수증 발행은 불가능합니다.\n지출증빙/현금영수증을 원하시는 경우 반드시 주문 시 입금자 정보 입력 란에 지출증빙/소득공제 발행 신청을 선택 후 요청정보(주민등록번호/현금영수증 카드번호/휴대폰번호/사업자등록번호)를 입력하셔야 합니다",
  },
  {
    category: "category5",
    question: "현금영수증 발급을 다른 사람으로 하고 싶습니다.",
    answer:
      "타인의 명의로 현금영수증 발급을 원하실 경우 상품 주문 시 발급 받으실 고객님의 정보(주민등록번호/현금영수증 카드번호/휴대폰번호)를 입력해주시면 됩니다.",
  },
  {
    category: "category5",
    question: "주문건의 결제 금액을 나눠서 영수증 발행이 가능한가요?",
    answer:
      "주문하신 내역 중 일부 상품에 대해서만 분할로 영수증을 발급 받으실 수는 없습니다.",
  },
  {
    id: "category6__alreadySigned",
    category: "category6",
    question:
      "카카오 계정으로 로그인 하면 '이미 카카오로 가입하신 이메일입니다'라고 나오는데 어떻게 해야하나요?",
    answer:
      "휴대전화 번호를 변경하셨거나 카카오톡 계정을 탈퇴하고 새로 가입하신 경우 이런 문제가 발생할 수 있습니다.이 경우 새로운 정보가 반영될 수 있도록 번거로우시더라도 고객센터로 문의 부탁드립니다.\n오늘의집 고객센터 1670-0876 (운영 시간 : 평일 09:00~18:00) - 마이페이지 > 나의 쇼핑 > 고객센터 > 1:1 카톡 상담하기 - 카카오 플러스친구 '오늘의집 고객센터'로 문의",
  },
  {
    category: "category6",
    question:
      "우리의 집에 가입한 적이 없는데 이미 가입된 이메일이라고 나와요. 어떻게 하나요?",
    answer:
      "현재 오늘의집은 회원 가입 시 이메일 인증 절차를 거치므로 이러한 문제가 발생되지 않지만 인증 절차 도입 전 다른 고객님께서 이메일을 잘못 입력하신 경우 '이미 가입한 이메일'이란 메시지가 노출될 수 있습니다.\n이는 고객님의 정보가 노출된 것이 아니니 걱정하지 마시고, 번거로우시겠지만 고객센터로 문의 부탁드립니다.\n오늘의집 고객센터 1670-0876 (운영 시간 : 평일 09:00~18:00) - 마이페이지 > 나의 쇼핑 > 고객센터 > 1:1 카톡 상담하기 - 카카오 플러스친구 '오늘의집 고객센터'로 문의",
  },
  {
    category: "category6",
    question: "우리의 집 웹에서는 애플 아이디로 로그인이 가능한가요?",
    answer:
      "애플 로그인은 앱에서만 사용이 가능합니다.\n고객님들의 편의를 위해 웹에서도 애플 로그인이 가능하도록 현재 개발 중에 있습니다.",
  },
  {
    category: "category6",
    question:
      "페이스북 간편 로그인 시도 시 '추가 정보 입력창과 이메일 주소 입력시 이미 가입된 이메일입니다'라고 나오는데 어떻게 하나요?",
    answer:
      "페이스북 계정을 변경하셨거나 새로 생성하여 간편 로그인 시도 시 이러한 오류가 발생될 수 있습니다.\n우선 로그인하시려는 페이스북 계정의 [메뉴 → 설정 및 개인정보 → 설정 → 앱 및 웹사이트 → 오늘의집 연동]이 정상적으로 되었는지 확인 부탁드리며, 정상적으로 로그인이 되지 않으시다면, 번거로우시겠지만 고객센터로 문의 부탁드립니다.\n오늘의집 고객센터 1670-0876 (운영 시간 : 평일 09:00~18:00) - 마이페이지 > 나의 쇼핑 > 고객센터 > 1:1 카톡 상담하기 - 카카오 플러스친구 '오늘의집 고객센터'로 문의",
  },
  {
    category: "category6",
    question: "비밀번호 변경은 어떻게 하나요?",
    answer:
      "우측 상단 프로필 사진을 클릭 후 [마이홈 > 설정 > 비밀번호 변경] 페이지에서 비밀번호 변경이 가능합니다.",
  },
  {
    category: "category6",
    question: "회원탈퇴 후 재가입이 가능한가요?",
    answer: "우리의집 재가입은 언제든지 가능합니다.",
  },
  {
    category: "category6",
    question: "회원정보를 수정하고 싶은데 어떻게 해야하나요?",
    answer:
      "우측 상단 프로필 사진을 클릭 후 [마이홈 > 설정 > 회원정보수정] 페이지에서 회원 정보 수정이 가능합니다.",
  },
  {
    category: "category6",
    question: "푸시 알림 설정은 어떻게 하나요?",
    answer:
      "모바일앱을 통해 설정 가능하며 [마이페이지 > 설정 > 푸시 알림 설정]을 통해 푸시알림 수신 설정이 가능합니다.",
  },
  {
    category: "category6",
    question: "회원탈퇴는 어떻게 하나요?",
    answer:
      "우리의집 PC 웹사이트 또는 모바일 앱에서 가능합니다.\nPC 웹사이트: 우측 상단 프로필 사진 클릭 후 [마이페이지 > 설정 > 회원정보수정] 상단의 탈퇴하기 버튼 클릭해주세요.\n모바일 앱: [마이페이지 > 설정 > 프로필 수정] 하단의 탈퇴하기 버튼을 클릭해주세요.\n전문가 회원은 이메일 pro@bucketplace.net으로 문의해주시면 감사하겠습니다.",
  },
  {
    category: "category7",
    question:
      "'좋아요'를 누른 콘텐츠(사진/집들이/노하우/등)들은 어디서 볼 수 있나요?",
    answer:
      "우측 상단 프로필 사진을 클릭 후 [마이홈 > 좋아요] 페이지에서 확인 가능합니다.",
  },
  {
    id: "category7__productDetail",
    category: "category7",
    question: "제품의 자세한 정보는 어떻게 알 수 있나요?",
    answer:
      "각 제품의 상세 페이지에서 확인 가능하며, 더욱 자세한 정보는 제품상세페이지 내 문의하기를 통해 판매 업체에 문의 가능합니다.",
  },
  {
    category: "category7",
    question: "좋지않은 댓글을 받았습니다. 어떻게 해야하나요?",
    answer: "댓글 아래에 신고 버튼을 눌러 댓글 신고가 가능합니다.",
  },
  {
    category: "category7",
    question: "인기게시글, 인기유저의 기준은 무엇인가요?",
    answer:
      "오늘의집 추천 알고리즘에 따라 인기게시글과 유저가 뽑히게 됩니다.\n많은 분들에게 공감되는 인테리어 사진을 통해 인기 유저가 되어보세요.",
  },
  {
    category: "category7",
    question: "우리의집에서 다루는 컨텐츠는 어떤것이 있나요?",
    answer:
      "오늘의집에서 다루는 컨텐츠는 크게 사진, 온라인집들이, 전문가집들이, 노하우로 구분됩니다.\n사진은 자신만의 인테리어 공간 및 소품등을 공유하는 소셜네트워크 공간입니다.여러분의 스타일링 사진을 자유롭게 올려주세요.\n온라인집들이는 오늘의집 사용자들이 직접 집을 꾸민 후 인테리어에 후기와 구체적인 정보를 공유하는 컨텐츠 입니다. 여러분의 집을 소개하거나 다른 분의 집을 제보해주세요.\n전문가집들이는 오늘의집의 인테리어 전문가들이 실제 시공 후 작성하는 포트폴리오 입니다.\n노하우는 오늘의집유저, 파워블로거, 인테리어 전문가 분들이 만들어 가는 인테리어 가이드입니다. 여러분의 인테리어 관심을 언제나 열려있는 노하우 작가신청을 통해 공유해주세요.",
  },
  {
    category: "category7",
    question: "집들이와 노하우는 어떻게 업로드하나요?",
    answer:
      "오늘의집 홈페이지 접속하신 다음 우측 상단 연필 모양 버튼 클릭 후 노하우 또는 집들이 업로드가 가능합니다.\n모바일앱과 모바일웹은 지원하지 않으며 PC 웹사이트에서만 작성 가능합니다.",
  },
  {
    category: "category7",
    question: "인테리어 상담과 시공을 직접해주시나요?",
    answer:
      "오늘의집 전문가 찾기 서비스는 오늘의집 사용자와 인테리어 전문가님을 중개하는 서비스로,\n오늘의집이 직접 상담과 시공을 진행하지 않습니다.",
  },
  {
    category: "category7",
    question: "전문가 계정은 무엇인가요?",
    answer:
      "오늘의집 전문가 계정은 홍보가 어려웠던 인테리어 전문가님에게\n홍보를 효율적으로 관리하실 수 있게 마련된 계정입니다.\n전문가 계정을 통해 오늘의집 100만 사용자들에게 더욱 손쉬운 홍보가 가능하니 많은 이용 부탁드립니다.",
  },
  {
    category: "category7",
    question: "전문가 계정 신청은 어떻게 하나요?",
    answer:
      "오늘의집 전문가 전용 페이지 [Pro.ohou.se] 에서 가입 신청 하시거나,\n[오늘의집 > 회원가입 > 시공전문가] 에서 신청하실 수 있습니다.\n자세한 문의는 고객센터 혹은 카카오톡 '오늘의집 전문가'를 이용 부탁드립니다.!",
  },
  {
    category: "category7",
    question: "모바일앱이 정상동작하지 않아요. 어떻게 해야하나요?",
    answer:
      "먼저 사용하시는 어플리케이션 버전이 최신 버전인지 확인부탁드립니다.\n최신 버전에서도 계속 장애가 발생한다면, [마이페이지 > 설정 > 의견보내기] 를 통해 신고 가능합니다.\n신고시 반드시 사용하시는 플랫폼(안드로이드/iOS), 어플리케이션 버전 그리고 장애 내용과 발생되는 과정을 설명 부탁드립니다.\n스크린샷을 첨부해 주시면 더욱 원활한 처리가 가능하니 참고 부탁드립니다.",
  },
  {
    category: "category7",
    question:
      "회원가입시 이미 가입된 이메일이라고 표시됩니다. 어떻게 해야하나요?",
    answer: "contact@bucketplace.net으로 문의 부탁드립니다.",
  },
  {
    category: "category7",
    question: "우리의집을 PC나 맥에서도 볼 수 있나요?",
    answer:
      "가능합니다. 아이폰,안드로이드 어플리케이션과 함께 웹사이트도 제공하고있으며, 웹브라우저를 통해 https://ohou.se 로 접속하시면 보실 수 있습니다.",
  },
  {
    category: "category7",
    question: "우리의집을 앱으로도 볼 수 있나요?",
    answer:
      "가능합니다. 웹사이트와 함께 아이폰, 안드로이드 어플리케이션도 제공하고있으며, 앱스토어 또는 플레이스토어에서 '우리의집'을 검색해 다운받을 수 있습니다.",
  },
  {
    category: "category7",
    question: "온라인 집들이에 우리집을 소개하고 싶어요. 어떻게 하나요?",
    answer:
      "우리의집 PC 웹사이트에 접속하신 다음 우측 상단 연필 모양 버튼 클릭 후 집들이 선택 시 신청이 가능합니다.",
  },
  {
    category: "category7",
    question: "상담방법과 상담시간 유선번호는 어떻게 되나요?",
    answer:
      "상담 시간은 평일 09:00 ~ 18:00 (주말 & 공휴일 제외)이며, 전화번호는 1670-0876 입니다.\n우측 상단 프로필 사진을 클릭 후 [나의쇼핑 > 고객센터 > 1:1 카톡 상담하기] 를 이용하시면 상담원과 채팅으로 더욱 빠르고 편리하게 문의 가능합니다.",
  },
  {
    category: "category7",
    question: "입점 및 제휴/광고 문의는 어떻게 하나요?",
    answer:
      "입점 신청은 https://partners.ohou.se/partner/applications/new 에서,\n제휴/광고 문의는 https://ohou.se/contacts/new?type=request 페이지에서 신청 가능합니다.",
  },
  {
    category: "category7",
    question: "우리의집 채용은 어디에서 확인하나요?",
    answer: "http://bucketplace.co.kr/recruit 페이지에서 확인 가능합니다.",
  },
  {
    category: "category7",
    question:
      "제가 쓴 리뷰가 블라인드 처리되었어요, 블라인드 처리 기준은 무엇인가요?",
    answer: `우리의집은 상품의 실사용 정보를 얻기 힘든 가구 및 인테리어 제품 등의 구매 과정에서 해당 제품을 직접 사용한 유저들이 생생하고 진실된 리뷰를 올리고 이를 서로 활용하는 문화를 만들어가고자 합니다.`,
  },
];

const FAQ = () => {
  const [category, setCategory] = useState("all");
  const [cardOnOff, setCardOnOff] = useState(qnaList);

  function handleHash(tab, question) {
    setCardOnOff(
      cardOnOff.map((item) => {
        if (item.id === `${tab}__${question}`) {
          console.log({ item, tab, question });
          item.show = true;
          item.onMoveToElement?.();
        }
        return item;
      })
    );
    setCategory(tab);

  }

  const showList = cardOnOff.map((item, index) => {
    if (category === "all" || category === item.category) return <QnaCard
      key={item?.id ?? index}
      item={item}
      index={index}
      cardOnOff={cardOnOff}
      setCardOnOff={setCardOnOff}
    />;
  });

  return (
    <div>
      <Header />
      <CsBox handleHash={handleHash} />
      <div className="Blank" style={{ height: "450px" }}></div>

      <HorizonLine text="😊" />

      <CategoryFilter
        categories={categories}
        category={category}
        setCategory={setCategory}
      />
      <div className="faq-parent">
        <div className="faq-list">
          {showList}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
